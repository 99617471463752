/* eslint-disable @typescript-eslint/naming-convention */
import { useRouter } from 'next/router'
import { useCallback, useEffect, useRef, useState } from 'react'

import { useAdCallbackDispatch } from '../../context/ads/callback.context'
import { useFilledSlots } from '../../context/ads/filled.context'
import getSlotDivId from '../../helpers/ads/getSlotDivId'
import managePPID from '../../helpers/ads/managePpid'
import type {
    AdPositionDevice,
    DefineGptSlotProps,
    DefineGptSlotsProps,
    GoogleTagPubAdsService,
    GoogleTagSlot,
    GoogleTagSlotRenderEvent,
    GptSlotDefinition,
    NativeGoogleTagIdCallback,
    SlotDivId,
    TargetingArguments,
    ZoneName
} from '../../types/ads/Ad.interface'
import useUpdatedRef from '../functional/useUpdatedRef'
import useDeviceType from '../layout/useDeviceType'
import { checkDfpDivExists, getSizeFromWindow } from './useExistingSlots'
import { ZONE_NAME_SEPARATOR, ZONE_NAME_TARGETING_KEY } from './zoneNameConstants'

type ApplyChangesProps = {
    slotsToDefine?: GptSlotDefinition[]
    slotsToDestroy?: string[] | GptSlotDefinition[]
    slotsToRefresh?: string[] | GptSlotDefinition[]
    slotsToDisplay?: string[] | GptSlotDefinition[]
}

type ApplyChangesCallback = (
    changes: ApplyChangesProps,
    actions: {
        display: NativeGoogleTagIdCallback
        define: DefineGptSlotsProps
        refresh: NativeGoogleTagIdCallback
        destroy: NativeGoogleTagIdCallback
    }
) => void

// {
//     "serviceName": "publisher_ads",
//     "slot": {},
//     "isEmpty": false,
//     "slotContentChanged": true,
//     "size": [
//         700,
//         350
//     ],
//     "advertiserId": 5354451114,
//     "campaignId": 3254743615,
//     "creativeId": 138452712271,
//     "creativeTemplateId": null,
//     "labelIds": null,
//     "lineItemId": 6387852714,
//     "sourceAgnosticCreativeId": 138452712271,
//     "sourceAgnosticLineItemId": 6387852714,
//     "isBackfill": false,
//     "yieldGroupIds": null,
//     "companyIds": null
// }

function parseTargetingArgument(argument: number | string): string
function parseTargetingArgument(argument: number[] | string[] | (string | number)[]): string[]
function parseTargetingArgument(
    argument: string | number | string[] | number[] | (string | number)[]
): string | string[]
// eslint-disable-next-line prefer-arrow-functions/prefer-arrow-functions
function parseTargetingArgument(
    argument: string | number | string[] | number[] | (string | number)[]
): string | string[] {
    if (Array.isArray(argument)) {
        return argument.map(String)
    }
    return String(argument)
}
const applyTargetingArguments = (
    targetableGoogleItem: GoogleTagSlot | GoogleTagPubAdsService,
    args: TargetingArguments
) => {
    Object.keys(args).forEach(key => {
        if (args[key] === null) targetableGoogleItem.clearTargeting(key)
        else targetableGoogleItem.setTargeting(key, parseTargetingArgument(args[key]))
    })
}

const slotIdMapper = (slot: string | GptSlotDefinition) => (typeof slot === 'string' ? slot : slot.divId)

const getGoogleTag = () => {
    try {
        if (!window) {
            return null
        }
        window.googletag = window.googletag || {}
        // @ts-expect-error: cmd is mutable
        window.googletag.cmd = window.googletag.cmd || []
        return window.googletag
    } catch (err) {
        return null
    }
}

type UseGoogleTagProps = {
    dfpZones: AdPositionDevice
    dfpNetworkId: string
    collapseEmptyDivs?: boolean
    singleRequest?: boolean
    disableInitialLoad?: boolean
    enableDisplayCalls?: boolean
    onSlotRender?: (slot: { event: GoogleTagSlotRenderEvent; divId: string; slot: GoogleTagSlot }) => void
    onSlotDisplay?: (slots: GoogleTagSlot[], display: NativeGoogleTagIdCallback) => void
    onSlotRefresh?: (slots: GoogleTagSlot[], refresh: NativeGoogleTagIdCallback) => void
    viewableRefresh?: boolean
    viewableRefreshDelay?: number
    maxRefreshesAfterViewable?: number
}

const useGoogleTag = ({
    dfpZones,
    dfpNetworkId,
    collapseEmptyDivs,
    singleRequest,
    disableInitialLoad,
    enableDisplayCalls = true,
    onSlotRender,
    onSlotDisplay,
    onSlotRefresh,
    viewableRefresh = true,
    viewableRefreshDelay = 40000,
    maxRefreshesAfterViewable = 8
}: UseGoogleTagProps) => {
    // store global gogoletag to ref and expose immediately
    const { current: googletag } = useRef(getGoogleTag())
    // reference to defined googletag slots, key is divId since divId is the only thing that is unique
    // single adUnit can appear in multiple divs
    // https://developers.google.com/publisher-tag/reference#googletag.slot
    const slotsRef = useRef<Record<SlotDivId, GoogleTagSlot>>({})
    const zoneNameConfigMap = useRef<Record<ZoneName, GptSlotDefinition>>({})
    const findExistingDivIds = useCallback((zoneName: string | string[]) => {
        const parsedNames = Array.isArray(zoneName) ? zoneName : [zoneName]
        const matchingDivIds = Object.entries(zoneNameConfigMap.current)
            .filter(([name]) => parsedNames.some(n => name.includes(n)))
            .map(([, { divId }]) => divId)
        return matchingDivIds.filter(id => slotsRef.current[id])
    }, [])

    const dfpZonesRef = useUpdatedRef(dfpZones)

    const [deviceType] = useDeviceType()
    const findZoneConfigs = useCallback(
        (zoneName: string | string[]) => {
            const parsedNames = Array.isArray(zoneName) ? zoneName : [zoneName]
            const matchingConfigs = Object.entries({ ...dfpZonesRef.current, ...zoneNameConfigMap.current })
                .filter(
                    ([name]) =>
                        name.startsWith(deviceType === 'mobile' ? 'M' : 'D') && parsedNames.some(n => name.includes(n))
                )
                .map(([, config]) => config)
            return matchingConfigs
        },
        [dfpZones, deviceType]
    )

    const router = useRouter()
    // just keep track of which slots have been displayed, probably can avoid this by checking the slot object itself
    const displayedSlotsRef = useRef({})
    const slotWasDisplayedRef = useRef<Record<string, true>>({})

    // keep track of refreshTimeouts
    const visibleSlotTimeouts = useRef<Record<string, number>>({})
    const clearVisibleTimeouts = useCallback((divIds: string[]) => {
        divIds.map(s => visibleSlotTimeouts[s]).forEach(timeout => typeof timeout === 'number' && clearTimeout(timeout))
    }, [])

    const withGoogleTag = useCallback(
        <CallbackType extends (...args: any[]) => void>(cb?: CallbackType) =>
            ((...rest: Parameters<CallbackType>) => {
                googletag?.cmd.push(() => {
                    cb?.(...rest)
                })
            }) as CallbackType,
        []
    )

    const [, setFilledGptSlots] = useFilledSlots()
    const defineGptSlot: DefineGptSlotProps = useCallback((slot, targetingArguments) => {
        const {
            adUnit,
            sizes: configSizes,
            divId,
            outOfPage,
            targetingArguments: slotTargetingArguments,
            zoneName
        } = slot
        const sizesFromWindow = typeof window !== 'undefined' && getSizeFromWindow(divId)
        const sizes = Array.isArray(sizesFromWindow) ? sizesFromWindow : configSizes
        const realDivId = getSlotDivId(slot, typeof window !== 'undefined' && window?.googletag?.enums?.OutOfPageFormat)
        const hasRendered = checkDfpDivExists(divId) || checkDfpDivExists(adUnit)
        if (!realDivId || !hasRendered || !googletag) {
            return
        }
        if (zoneName) {
            zoneNameConfigMap.current[zoneName] = slot
            zoneNameConfigMap.current[zoneName].zoneName = zoneName
        }
        const hasFromBefore = !!slotsRef.current[realDivId]
        const googleTagSlot: GoogleTagSlot | null =
            slotsRef.current[realDivId] ||
            (outOfPage
                ? googletag.defineOutOfPageSlot(`/${dfpNetworkId}/${adUnit}`, realDivId)
                : googletag.defineSlot(`/${dfpNetworkId}/${adUnit}`, sizes, realDivId))

        if (!hasFromBefore && googleTagSlot) {
            googleTagSlot.addService(googletag.pubads())
            slotsRef.current[divId] = googleTagSlot
            applyTargetingArguments(googleTagSlot, { rtl_refresh: 'false' })
        }
        if (!googleTagSlot) return
        if (typeof zoneName === 'string') {
            googleTagSlot.setTargeting(ZONE_NAME_TARGETING_KEY, zoneName.split(ZONE_NAME_SEPARATOR))
        }
        if (targetingArguments) applyTargetingArguments(googleTagSlot, targetingArguments)
        if (slotTargetingArguments) applyTargetingArguments(googleTagSlot, slotTargetingArguments)
        if ('cookieDeprecationLabel' in navigator) {
            ;(navigator.cookieDeprecationLabel as any).getValue().then(label => {
                googleTagSlot.setTargeting('Chrome', label)
            })
        }
    }, [])

    const defineSlots: DefineGptSlotsProps = useCallback(
        (slots, targetingArguments) => {
            // debugLog('gptDebug defining gpt slots', slots)
            if (!slots || !googletag) {
                return
            }
            slots.forEach(s => defineGptSlot(s, targetingArguments))
        },
        [defineGptSlot]
    )

    const nativeRefresh = useCallback((divIdsToRefresh: string[], targetingArguments?: TargetingArguments) => {
        const refreshPayload = divIdsToRefresh.map(id => slotsRef.current[id]).filter(Boolean)
        googletag?.pubads().refresh(refreshPayload)
        if (targetingArguments) refreshPayload.forEach(slot => applyTargetingArguments(slot, targetingArguments))
    }, [])

    const mapIdsToSlots = useCallback((ids: string[] = []) => ids?.map(id => slotsRef.current[id]).filter(Boolean), [])

    const handleCustomRefresh = useCallback(
        (divIdsToRefresh: string[], targetingArguments?: TargetingArguments) => {
            if (typeof onSlotRefresh !== 'function') {
                return nativeRefresh(divIdsToRefresh, targetingArguments)
            }
            try {
                return onSlotRefresh(
                    mapIdsToSlots(divIdsToRefresh),
                    withGoogleTag(ids => nativeRefresh(ids, targetingArguments))
                )
            } catch (err) {
                // eslint-disable-next-line no-console
                // console.error('There was an error runnig custom Ad refresh handler, running native', err)
                return nativeRefresh(divIdsToRefresh, targetingArguments)
            }
        },
        [onSlotRefresh, nativeRefresh, withGoogleTag, mapIdsToSlots]
    )

    const refreshSlots: NativeGoogleTagIdCallback = useCallback(
        (divIds, targetingArguments) => {
            // debugLog('gptDebug refreshing gpt slots', divIds)
            if (!(divIds?.length > 0)) {
                return
            }
            const slotsToRefresh = mapIdsToSlots(divIds)
            slotsToRefresh.forEach(slot => {
                if (!slot) return
                const id = slot.getSlotElementId()
                if (!id) return
                if (!slotWasDisplayedRef.current[id]) {
                    applyTargetingArguments(slot, { rtl_refresh: 'false' })
                    slotWasDisplayedRef.current[id] = true
                    return
                }
                applyTargetingArguments(slot, { rtl_refresh: 'true' })
            })

            // debugLog('refreshing', cause, slotsToRefresh, divIds)
            if (!(slotsToRefresh?.length > 0)) {
                return
            }
            setFilledGptSlots(
                divIds.reduce((acc, id) => {
                    acc[id] = false
                    return acc
                }, {})
            )

            handleCustomRefresh(divIds, targetingArguments)
        },
        [setFilledGptSlots, handleCustomRefresh]
    )
    const refreshGoogleTagSlots = withGoogleTag(refreshSlots)

    const nativeDisplay: NativeGoogleTagIdCallback = useCallback(divIds => {
        divIds.forEach(divId => {
            if (divId) {
                googletag?.display(slotsRef?.current?.[divId] || divId)
            }
        })
    }, [])

    const handleCustomDisplay: NativeGoogleTagIdCallback = useCallback(
        divIdsToDisplay => {
            if (typeof onSlotDisplay !== 'function') {
                return nativeDisplay(divIdsToDisplay)
            }
            try {
                return onSlotDisplay(mapIdsToSlots(divIdsToDisplay), withGoogleTag(nativeDisplay))
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error('There was an error runnig custom Ad display handler, running native', err)
                return nativeDisplay(divIdsToDisplay)
            }
        },
        [onSlotDisplay, nativeDisplay, withGoogleTag, mapIdsToSlots]
    )

    const displaySlots: NativeGoogleTagIdCallback = useCallback(
        divIds => {
            // const divIds = divIdsIn.filter(checkDfpDivExists)
            if (!(divIds?.length > 0)) {
                return
            }
            const toRefresh: string[] = []
            const toDisplay: string[] = []
            divIds.forEach(id => {
                if (!slotsRef?.current?.[id]) {
                    return
                }
                slotWasDisplayedRef.current[id] = true
                if (displayedSlotsRef.current[id]) {
                    toRefresh.push(id)
                }
                displayedSlotsRef.current[id] = true
                toDisplay.push(id)
            })
            if (toDisplay.length > 0) {
                handleCustomDisplay(toDisplay)
            }
            // if (toRefresh.length > 0) {
            //     refreshSlots(toRefresh)
            // }
        },
        [handleCustomDisplay]
    )
    // eslint-disable-next-line no-unused-vars
    const destroySlots: NativeGoogleTagIdCallback = useCallback(divIds => {
        // debugLog('gptDebug destoryingy gpt slots', divIds)
        if (!(divIds?.length > 0)) {
            return
        }
        const slotsForDestruction = divIds.map(id => slotsRef.current[id])
        googletag?.destroySlots(slotsForDestruction)
        divIds.forEach(id => {
            if (displayedSlotsRef.current[id]) {
                delete displayedSlotsRef.current[id]
            }
            if (slotsRef?.current?.[id]) {
                delete slotsRef.current[id]
            }
        })
        setFilledGptSlots(
            divIds.reduce((acc, id) => {
                acc[id] = { filled: false, sizes: null }
                return acc
            }, {})
        )
    }, [])

    const slotRefreshes = useRef({})
    const handleSlotImpressionViewable = useCallback(
        (divId: string) => {
            if (!divId) {
                return
            }
            if (typeof visibleSlotTimeouts.current[divId] !== 'undefined') {
                return
            }
            if (slotRefreshes.current[divId] && slotRefreshes.current[divId] >= maxRefreshesAfterViewable) {
                return
            }
            visibleSlotTimeouts.current[divId] = setTimeout(() => {
                delete visibleSlotTimeouts.current[divId]
                refreshGoogleTagSlots([divId])
                if (!slotRefreshes.current[divId]) {
                    slotRefreshes.current[divId] = 1
                } else {
                    slotRefreshes.current[divId] += 1
                }
            }, viewableRefreshDelay || 40000) as unknown as number
        },
        [viewableRefreshDelay]
    )
    const destroyOnVisibleTimeouts = useCallback(() => {
        Object.values(visibleSlotTimeouts.current).forEach(timeout => clearTimeout(timeout))
        slotRefreshes.current = {}
        visibleSlotTimeouts.current = {}
    }, [])

    const [initialised, setInitialised] = useState(false)
    const initGpt = useCallback(() => {
        if (initialised || !googletag) {
            return
        }
        if (collapseEmptyDivs) {
            googletag.pubads().collapseEmptyDivs(true)
        }
        if (singleRequest) {
            googletag.pubads().enableSingleRequest()
        }
        // Yieldlove handles displaying and loading of ads
        if (disableInitialLoad) {
            googletag.pubads().disableInitialLoad()
        }
        googletag.pubads().addEventListener('slotRenderEnded', (event: GoogleTagSlotRenderEvent) => {
            // Here lies all slot render info
            onSlotRender?.({ event, divId: event?.slot?.getSlotElementId?.(), slot: event?.slot })
        })

        if (viewableRefresh) {
            googletag.pubads().addEventListener('impressionViewable', event => {
                const { slot } = event
                const id = slot.getSlotElementId()
                handleSlotImpressionViewable(id)
            })
        }
        googletag.pubads().setPublisherProvidedId(managePPID())
        googletag.enableServices()
        setInitialised(true)
    }, [initialised, disableInitialLoad, collapseEmptyDivs, singleRequest])

    const applyChangesHot = useCallback(
        (changes: ApplyChangesProps, targetingArguments?: TargetingArguments, callback?: ApplyChangesCallback) => {
            setTimeout(() => {
                const { slotsToDefine = [], slotsToDestroy = [], slotsToRefresh = [], slotsToDisplay = [] } = changes
                if (
                    !(
                        slotsToDefine?.length > 0 ||
                        slotsToDestroy?.length > 0 ||
                        slotsToRefresh?.length > 0 ||
                        slotsToDisplay?.length > 0
                    ) ||
                    !googletag
                ) {
                    return
                }

                const idsToDisplay = slotsToDisplay.map(slotIdMapper)
                const idsToRefresh = slotsToRefresh.map(slotIdMapper)
                const idsToDestroy = slotsToDestroy.map(slotIdMapper)
                // clear timeouts for slots that are changing in any way (except define since those don't have a timeout)
                clearVisibleTimeouts([...idsToDisplay, ...idsToRefresh, ...idsToDestroy])
                // group all actions into a single googletag command
                googletag.cmd.push(() => {
                    if (targetingArguments) applyTargetingArguments(googletag.pubads(), targetingArguments)
                    destroySlots(idsToDestroy)
                    defineSlots(slotsToDefine)
                    // Once we've destroyed and defined needed slots, reset displayed list
                    displayedSlotsRef.current = {}
                    initGpt()
                    // Yieldlove handles ad displaying/refreshing if it's active
                    if (enableDisplayCalls) {
                        displaySlots(idsToDisplay)
                        refreshSlots(idsToRefresh)
                    }
                })

                if (typeof callback === 'function') {
                    googletag.cmd.push(() => {
                        callback(
                            {
                                slotsToDefine,
                                slotsToDestroy,
                                slotsToRefresh: idsToRefresh,
                                slotsToDisplay: idsToDisplay
                            },
                            { display: displaySlots, define: defineSlots, refresh: refreshSlots, destroy: destroySlots }
                        )
                    })
                }
            }, 0) // set timeout 0, to send the command to the end of the queue
        },
        [displaySlots, refreshSlots, destroySlots, defineSlots, initGpt, clearVisibleTimeouts]
        // ['displaySlots', 'refreshSlots', 'destroySlots', 'defineSlots', 'initGpt', 'clearVisibleTimeouts'],
        // 'ads.provider usegoogletag applyChanges'
    )

    const applyChangesRef = useUpdatedRef(applyChangesHot)

    useEffect(() => {
        const onRouteChangeStart = () => {
            destroyOnVisibleTimeouts()
            displayedSlotsRef.current = {}
            slotWasDisplayedRef.current = {}
        }
        router.events.on('routeChangeStart', onRouteChangeStart)
        return () => {
            destroyOnVisibleTimeouts()
            router.events.off('routeChangeStart', onRouteChangeStart)
        }
    }, [])

    const dispatchAdCallbacks = useAdCallbackDispatch()
    useEffect(() => {
        if (!applyChangesRef.current || !dispatchAdCallbacks || !deviceType) {
            return
        }
        dispatchAdCallbacks({
            ready: true,
            displayAd: zoneNames => {
                const slotsToRefresh = findExistingDivIds(zoneNames)
                const slotsToDefine = findZoneConfigs(zoneNames).filter(({ divId }) => {
                    return !slotsRef.current[divId]
                })

                applyChangesRef.current({
                    slotsToDefine,
                    slotsToDisplay: slotsToDefine.map(({ divId }) => divId),
                    slotsToRefresh
                })
            },
            refreshAd: zoneNames => {
                const slotsToRefresh = findExistingDivIds(zoneNames)
                if (slotsToRefresh.length > 0) {
                    applyChangesRef.current({ slotsToRefresh })
                }
            },
            destroyAd: zoneNames => {
                const slotsToDestroy = findExistingDivIds(zoneNames)
                if (slotsToDestroy.length > 0) {
                    applyChangesRef.current({ slotsToDestroy })
                }
            }
        })
    }, [dispatchAdCallbacks, findExistingDivIds, deviceType])

    return [applyChangesRef, slotsRef] as const
}

export default useGoogleTag
