import { useUIDSeed } from 'react-uid'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

import { Columns } from '../../../Columns'
import { ConditionalWrapper } from '../../../ConditionalWrapper'
import { MenuItem } from '../MenuItem'
import styles from './Menu.style'

const MenuStyled = styled.div(props => ({ ...styles(props) }))

const handleGFCRevoke = () => {
    window.Didomi.preferences.show()
}

function Menu({ links, variant, columns, spacing, gtmData, ...rest }) {
    const uid = useUIDSeed()

    if (!links) {
        return null
    }

    return (
        <MenuStyled variant={variant} {...rest}>
            <div className="itemContainer">
                <ConditionalWrapper condition={columns > 0} Wrapper={Columns} columns={columns} spacing={spacing}>
                    {links.map((link, index) =>
                        !link?.title.includes('(popup)') ? (
                            <MenuItem
                                variant={variant}
                                key={uid(`${index}-${link.href}`)}
                                title={link.title}
                                href={link.href}
                                gtmData={gtmData}
                            />
                        ) : (
                            <span key={uid(`${index}-${link.href}`)} className="menuItemButton">
                                <button
                                    type="button"
                                    title={link.title.replace('(popup)', '')}
                                    onClick={() => {
                                        handleGFCRevoke()
                                    }}>
                                    {link.title.replace('(popup)', '')}
                                </button>
                            </span>
                        )
                    )}
                </ConditionalWrapper>
            </div>
        </MenuStyled>
    )
}

const menuVariants = Object.freeze({
    ALPHA: 'alpha', // Panel primary menu
    BETA: 'beta', // Panel secondary menu
    GAMMA: 'gamma', // Footer primary menu
    DELTA: 'delta', // Footer secondary menu
    EPSILON: 'epsilon' // Listing pages nav Widget
})

Menu.propTypes = {
    links: PropTypes.oneOfType([() => null, PropTypes.arrayOf(PropTypes.object)]),
    variant: PropTypes.oneOf([...Object.values(menuVariants)]),
    columns: PropTypes.number,
    spacing: PropTypes.number,
    gtmData: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
}

Menu.defaultProps = {
    links: undefined,
    variant: menuVariants.ALPHA,
    columns: undefined,
    spacing: null,
    gtmData: undefined
}

export { menuVariants }
export default withErrorBoundary(Menu, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[Menu]: ', error, componentStack)
    }
})
