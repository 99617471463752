import { toRem } from '../../../../helpers/theme'

const style = ({ variant }) => {
    const config = {
        alpha: {
            marginLeft: 0
        },
        beta: {
            marginLeft: toRem(-10)
        },
        gamma: {
            marginLeft: 0
        },
        delta: {
            marginLeft: toRem(-10)
        },
        epsilon: {
            marginLeft: toRem(-10)
        }
    }

    return {
        overflow: 'hidden',
        letterSpacing: 'initial',
        '.itemContainer': {
            marginLeft: config[variant]?.marginLeft || 0
        },

        '.menuItemButton': {
            display: 'inline-block',
            marginRight: toRem(10),

            '&::before': {
                content: `'\u00B7'`,
                marginRight: toRem(10)
            },

            '> button': {
                display: 'inline-block',
                color: '#797979',
                textTransform: 'none',
                fontSize: toRem(14),
                lineHeight: toRem(28),
                fontWeight: 'normal',

                '&:hover': {
                    color: '#000'
                }
            }
        }
    }
}

export default style
